import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers } from 'recompose';
import LinkProps from 'utils/ts/LinkProps';

/**
 * Adds onClick handler for router dispatch. Useful for Card etc, where we may
 * want to link the entire section but not block links/actions on nested elements.
 */
const withFallbackLink = ({ to: toOption }: options = {}) => compose(
  connect(),
  withHandlers({
    onClick: ({ dispatch, to: toProp, onClick }) => (e) => {
      if (onClick) onClick();

      // Don't fire for obviously interactive elements
      if (e.target.matches('a, button')) return;

      // Prefer props
      if (toProp) {
        dispatch(toProp);
      // Fallback to HOC options
      } else if (toOption) {
        dispatch(toOption);
      }
    },
  }),
  mapProps(R.omit(['to', 'dispatch'])),
);

interface options {
  to?: LinkProps;
}

export default withFallbackLink;
