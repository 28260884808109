import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { login } from 'state/ducks/user';
import FormBuilder from 'components/forms/FormBuilder/FormBuilder';

const mapDispatchToProps = dispatch => ({
  onSubmit: async (data) => {
    try {
      const resp = await dispatch(login(data));
      return { errors: { [FORM_ERROR]: resp } };
    } catch (e) {
      return { errors: { [FORM_ERROR]: 'Could not log in, please try again later.' } };
    }
  },
});

const SiteLoginForm = compose(
  withProps({
    actionAlt: {
      title: 'Forgot password?',
      to: { type: 'FORGOT_PASSWORD' },
    },
    fields: [
      {
        type: 'email',
        name: 'email',
        label: 'Email',
        required: true,
      },
      {
        type: 'password',
        name: 'password',
        label: 'Password',
        required: true,
      },
    ],
  }),
  connect(
    null,
    mapDispatchToProps,
  ),
)(FormBuilder);

export default SiteLoginForm;
