/**
 * SiteFlashes
 */
import { connect } from 'react-redux';
import FlashList from 'components/general/FlashList/FlashList';
import { getStaticFlashes } from 'ducks/flashes';

const mapStateToProps = state => ({
  flashes: getStaticFlashes(state),
});

const SiteFlashes = connect(
  mapStateToProps,
)(FlashList);

export default SiteFlashes;
