import classNames from 'classnames';
import withFallbackLink from 'components/hoc/withFallbackLink';
import React from 'react';
import LinkProps from 'utils/ts/LinkProps';

const Card: React.SFC<CardPropDef> & { Section?: React.SFC<CardSectionPropDef> }  = ({
  children,
  className,
  types,
  ...args
}) => (
  <div
    className={classNames(
      'card',
      types.map(type => `card--${type}`),
      className,
    )}
    {...args}
  >
    {children}
  </div>
);

Card.defaultProps = {
  className: '',
  types: [],
};

interface CardPropDef {
  children: any;
  className?: string;
  types?: string[];
}

const CardSection: React.SFC<CardSectionPropDef> = ({
  children,
  className,
  types = [],
  ...args
}) => {
  const classes = classNames(
    'card__section',
    className,
    types.map(type => `card__section--${type}`),
  );
  return (
    <div className={classes} {...args}>
      {children}
    </div>
  );
};

CardSection.defaultProps = {
  className: '',
};

interface CardSectionPropDef {
  children: any;
  className?: string;
  types?: string[];
  to?: LinkProps;
}

Card.Section = withFallbackLink()(CardSection);

export default Card;
