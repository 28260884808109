import Card from 'components/general/Card/Card';
import SEOMeta from 'containers/SEOMeta';
import SiteFlashes from 'containers/SiteFlashes';
import SiteLoginForm from 'containers/SiteLoginForm';
import React, { Fragment } from 'react';

const Login = () => (
  <Fragment>
    <SEOMeta title="Login" />
    <Card>
      <Card.Section>
        <SiteFlashes />
        <h1>Login</h1>
        <SiteLoginForm data-test="site-login-form" />
      </Card.Section>
      {/*<Card.Section>*/}
      {/*  No account?<br />*/}
      {/*  <a href={"https://www.s-rminform.com/global-security-insight/"} target={"blank"}>Find out more about GSI here.</a>*/}
      {/*</Card.Section>*/}
    </Card>
  </Fragment>
);

export default Login;
